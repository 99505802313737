import React from "react";
import './Dashboard.modules.css';

import Navbar from "./Navbar/Navbar";

const Dashboard = ({activePage, profilePhoto, userName, userPrefix, children, ...rest}) => {
    return (
        <div className="dashboard-page">
            <Navbar 
                active={activePage}
                photo={profilePhoto}
                name={userName}
                prefix={userPrefix} 
            />
            <main className="main">
                {children}
            </main>
        </div>
    )
}

export default Dashboard;