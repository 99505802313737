import React, { useState, useEffect, useRef } from 'react';
import rawCountries from './rawCountries';
import './PhoneInput.modules.css';

// Dynamically import all SVG flag images from the 'flags' directory
const importAllFlags = (requireContext) => {
  const flags = {};
  requireContext.keys().forEach((key) => {
    try {
      const countryCode = key.match(/\/([a-z]{2})\.svg$/i)[1].toUpperCase();
      flags[countryCode] = requireContext(key);
    } catch (error) {
      console.error(`Error loading flag for ${key}:`, error);
    }
  });
  return flags;
};

// Adjust the path to your flags directory
const flags = importAllFlags(require.context('./flags', false, /\.svg$/));

const PhoneInput = ({
  allowedCountries = [],
  allowedRegions = [],
  disallowedCountries = [],
  disallowedRegions = [],
  searchable = false,
  pinnedCountries = [],
  defaultCountry = 'us',
  onChange, // Add the onChange prop
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cleanNumber, setCleanNumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const optionsRef = useRef([]);

  useEffect(() => {
    filterCountries();
  }, []);

  useEffect(() => {
    if (isDropdownVisible && searchable && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownVisible, searchable]);

  useEffect(() => {
    initializeDefaultCountry();
  }, [filteredCountries]);

  const filterCountries = () => {
    let filtered = rawCountries;

    if (allowedCountries.length > 0 || allowedRegions.length > 0) {
      filtered = filtered.filter(country =>
        allowedCountries.includes(country[2]) ||
        allowedRegions.some(region => country[1].includes(region))
      );
    }

    if (disallowedCountries.length > 0) {
      filtered = filtered.filter(country => !disallowedCountries.includes(country[2]));
    }

    if (disallowedRegions.length > 0) {
      filtered = filtered.filter(country => {
        return !disallowedRegions.some(region => country[1].includes(region));
      });
    }

    const pinned = pinnedCountries.map(iso2 => filtered.find(country => country[2] === iso2)).filter(Boolean);
    const notPinned = filtered.filter(country => !pinnedCountries.includes(country[2]));
    notPinned.sort((a, b) => a[0].localeCompare(b[0]));

    setFilteredCountries([...pinned, ...notPinned]);
  };

  const initializeDefaultCountry = () => {
    if (filteredCountries.length > 0) {
      const country = rawCountries.find(country => country[2].toUpperCase() === defaultCountry.toUpperCase());
      if (country) {
        setSelectedCountry(country);
      } else {
        setSelectedCountry(rawCountries.find(country => country[2].toUpperCase() === 'US'));
      }
    }
  };

  const handleCountrySelect = (countryCode) => {
    const country = rawCountries.find(country => country[2].toUpperCase() === countryCode.toUpperCase());
    setSelectedCountry(country);
    setIsDropdownVisible(false);
    setSearchTerm('');
    setCleanNumber('');
    setFormattedNumber('');
    if (onChange) {
      onChange('');
    }
  };

  const handlePhoneNumberChange = (e) => {
    const { value, selectionStart, selectionEnd } = e.target;
    const isDeletion = formattedNumber.length > value.length;
    let cleanedNumber = value.replace(/\D/g, '').replace(selectedCountry?.[3], ''); // Remove the country code part

    cleanedNumber = cleanedNumber.slice(0, 15);

    setCleanNumber(cleanedNumber);
    updateFormattedNumber(cleanedNumber, selectedCountry, isDeletion, selectionStart, selectionEnd);
  };

  const updateFormattedNumber = (number, country, isDeletion = false, cursorStart = 0, cursorEnd = 0) => {
    if (!country) {
      setFormattedNumber(number);
      return;
    }

    const countryCode = `+${country[3]} `;
    let formatted = countryCode;
    let numericIndex = 0;
    let newCursorPos = cursorStart + countryCode.length;

    if (country[4]) {
      const format = country[4];
      for (let i = 0; i < format.length; i++) {
        if (format[i] === '.') {
          if (numericIndex < number.length) {
            formatted += number[numericIndex];
            numericIndex++;
          } else {
            break;
          }
        } else {
          formatted += format[i];
          if (!isDeletion && cursorStart >= i + 1) {
            newCursorPos++;
          }
        }
      }
    } else {
      formatted += number;
    }

    setFormattedNumber(formatted);
    if (onChange) {
      onChange(formatted); // Pass the formatted number to the parent component
    }

    if (inputRef.current) {
      setTimeout(() => {
        if (isDeletion) {
          if (cursorStart === cursorEnd) {
            newCursorPos = cursorStart - 1;
            while (newCursorPos > countryCode.length && !/\d/.test(formatted[newCursorPos])) {
              newCursorPos--;
            }
            newCursorPos++;
          }
        } else {
          while (newCursorPos < formatted.length && !/\d/.test(formatted[newCursorPos])) {
            newCursorPos++;
          }
        }
        inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const lowercasedSearchTerm = e.target.value.toLowerCase();

    let filtered = rawCountries.filter(country =>
      country[0].toLowerCase().includes(lowercasedSearchTerm) ||
      country[2].toLowerCase().includes(lowercasedSearchTerm)
    );

    if (allowedCountries.length > 0 || allowedRegions.length > 0) {
      filtered = filtered.filter(country =>
        allowedCountries.includes(country[2]) ||
        allowedRegions.some(region => country[1].includes(region))
      );
    }

    if (disallowedCountries.length > 0) {
      filtered = filtered.filter(country => !disallowedCountries.includes(country[2]));
    }

    if (disallowedRegions.length > 0) {
      filtered = filtered.filter(country => {
        return !disallowedRegions.some(region => country[1].includes(region));
      });
    }

    const pinned = pinnedCountries.map(iso2 => filtered.find(country => country[2] === iso2)).filter(Boolean);
    const notPinned = filtered.filter(country => !pinnedCountries.includes(country[2]));
    notPinned.sort((a, b) => a[0].localeCompare(b[0]));

    setFilteredCountries([...pinned, ...notPinned]);
  };

  const handleHeaderClick = (e) => {
    e.preventDefault();
    setIsDropdownVisible((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setIsDropdownVisible(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!dropdownRef.current.contains(document.activeElement)) {
        setIsDropdownVisible(false);
      }
    }, 100);
  };

  const handleKeyDown = (e) => {
    if (!isDropdownVisible) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredCountries.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredCountries.length) % filteredCountries.length);
        break;
      case 'Enter':
        e.preventDefault();
        if (filteredCountries[highlightedIndex]) {
          handleCountrySelect(filteredCountries[highlightedIndex][2]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isDropdownVisible && optionsRef.current[highlightedIndex]) {
      optionsRef.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex, isDropdownVisible]);

  return (
    <div className="phone-input-wrapper">
      <div
        className={`phone-input-dropdown ${isDropdownVisible ? 'active' : ''} ${selectedCountry ? 'filled' : ''}`}
        ref={dropdownRef}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      >
        <div className="phone-input-dropdown-header" onMouseDown={handleHeaderClick}>
            {selectedCountry ? (
              <>
                <img src={flags[selectedCountry[2].toUpperCase()]} alt={selectedCountry[2]} className="flag-icon" />
              </>
            ) : (
              'Select'
            )}
          <span className={`phone-input-dropdown-arrow ${isDropdownVisible ? 'open' : ''} material-symbols-outlined`}>
            keyboard_arrow_down
          </span>
        </div>
        {isDropdownVisible && (
          <div className="phone-input-dropdown-menu">
            <div className="phone-input-dropdown-options">
              {searchable && (
                <div className="phone-input-dropdown-search">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    className="phone-input-search-input"
                    ref={searchInputRef}
                  />
                </div>
              )}
              {filteredCountries.map((country, index) => (
                <div
                  key={country[2]}
                  className={`phone-input-dropdown-option ${index === highlightedIndex ? 'highlighted' : ''}`}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  onClick={() => handleCountrySelect(country[2])}
                  ref={(el) => (optionsRef.current[index] = el)}
                >
                  {flags[country[2].toUpperCase()] ? (
                    <span><img src={flags[country[2].toUpperCase()]} alt={country[2]} className="flag-icon" /> {country[0]} (+{country[3]})</span>
                  ) : (
                    <span>{country[0]} (+{country[3]})</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
        <input
          ref={inputRef}
          type="text"
          id="phone"
          value={formattedNumber}
          onChange={handlePhoneNumberChange}
          className="phone-input-field"
        />
        <label htmlFor="phone-input" className="phone-placeholder-text">
          <div className="phone-label-text">Phone Number</div>
        </label>
    </div>
  );
};

export default PhoneInput;
