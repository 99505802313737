import { createContext, useReducer, useEffect } from 'react';

export const CourseContext = createContext();

export const courseReducer = (courseState, action) => {
    switch (action.type) {
        case 'ADD':
            return { 
                userCourses: [...(courseState.userCourses || []), action.payload]
            };
        case 'REMOVE':
            return { userCourses: [] };
        case 'UPDATE':
            return {
                userCourses: courseState.userCourses.map(course => 
                    course._id === action.payload._id? action.payload : course
                )
            }
        case 'DELETE':
            return {
                userCourses: courseState.userCourses.filter(course => course._id !== action.payload)
            };
        default:
            return courseState;
    }
};

export const CourseContextProvider = ({ children }) => {
    const [courseState, dispatchCourse] = useReducer(courseReducer, {
        userCourses: JSON.parse(localStorage.getItem('userCourses')) || []
    });

    // Update Context Provider from Local Storage
    useEffect(() => {
        localStorage.setItem('userCourses', JSON.stringify(courseState.userCourses))
    }, [courseState.userCourses]);

    return (
        <CourseContext.Provider value={{...courseState, dispatchCourse}}>
            { children }
        </CourseContext.Provider>
    )
}