import React, { useState, useEffect, useRef } from 'react';
import styles from './TimePicker.module.css';

const TimePicker = ({ popoverSide = 'bottom', children, placeholder, onChange, value, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [time, setTime] = useState(''); // No initial time
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [period, setPeriod] = useState('AM');
    const [isInitialized, setIsInitialized] = useState(false);

    const ref = useRef(null);
    const hoursRef = useRef(null);
    const minutesRef = useRef(null);
    const periodAMRef = useRef(null);
    const periodPMRef = useRef(null);

    useEffect(() => {
        if (value && !isInitialized) {
            // Initialize with the provided value
            const [initialHours, initialMinutes, initialPeriod] = value.split(/[:\s]/);
            setHours(initialHours);
            setMinutes(initialMinutes);
            setPeriod(initialPeriod);
            setTime(value);
            setIsInitialized(true); // Mark as initialized
        }
    }, [value, isInitialized]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        const handleFocusOutside = (event) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('focusin', handleFocusOutside);
        return () => {
            document.removeEventListener('focusin', handleFocusOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen && isInitialized) {
            validateAndFormatTime();
        }
    }, [isOpen, isInitialized]);

    useEffect(() => {
        if (hours && minutes) {
            const formattedTime = `${hours}:${minutes} ${period}`;
            setTime(formattedTime);
            if (onChange) {
                onChange(formattedTime);
            }
        }
    }, [hours, minutes, period, onChange]);

    const handleOpen = () => {
        if (!isInitialized && !value) {
            const currentTime = new Date();
            setHours(String(currentTime.getHours() % 12 || 12).padStart(2, '0'));
            setMinutes(String(currentTime.getMinutes()).padStart(2, '0'));
            setPeriod(currentTime.getHours() >= 12 ? 'PM' : 'AM');
            setIsInitialized(true); // Mark as initialized
        }
        setIsOpen(true);
    };

    const handleFocus = (event) => {
        if (!isOpen) {
            handleOpen();
        }
    };

    const handleTabClose = (event) => {
        if (event.key === 'Tab') {
            if (event.shiftKey) {
                // Back-tabbing
                if (event.target === hoursRef.current) {
                    setIsOpen(false);
                }
            } else {
                // Forward-tabbing
                if (event.target === periodPMRef.current) {
                    setIsOpen(false);
                }
            }
        }
    };

    const incrementHours = () => {
        let newHours = parseInt(hours, 10) || 0;
        newHours = newHours === 12 ? 1 : newHours + 1;
        setHours(String(newHours).padStart(2, '0'));
    };

    const decrementHours = () => {
        let newHours = parseInt(hours, 10) || 0;
        newHours = newHours === 1 ? 12 : newHours - 1;
        setHours(String(newHours).padStart(2, '0'));
    };

    const incrementMinutes = () => {
        let newMinutes = parseInt(minutes, 10) || 0;
        newMinutes = newMinutes === 59 ? 0 : newMinutes + 1;
        setMinutes(String(newMinutes).padStart(2, '0'));
    };

    const decrementMinutes = () => {
        let newMinutes = parseInt(minutes, 10) || 0;
        newMinutes = newMinutes === 0 ? 59 : newMinutes - 1;
        setMinutes(String(newMinutes).padStart(2, '0'));
    };

    const validateAndFormatTime = () => {
        let parsedHours = parseInt(hours, 10);
        if (isNaN(parsedHours) || parsedHours < 1) {
            parsedHours = 1;
        } else if (parsedHours > 12) {
            parsedHours = 12;
        }
        setHours(String(parsedHours).padStart(2, '0'));

        let parsedMinutes = parseInt(minutes, 10);
        if (isNaN(parsedMinutes) || parsedMinutes < 0) {
            parsedMinutes = 0;
        } else if (parsedMinutes > 59) {
            parsedMinutes = 59;
        }
        setMinutes(String(parsedMinutes).padStart(2, '0'));
    };

    const handleHoursBlur = () => {
        validateAndFormatTime();
    };

    const handleMinutesBlur = () => {
        validateAndFormatTime();
    };

    const getPopoverPosition = () => {
        switch (popoverSide) {
            case 'top':
                return { bottom: '100%', left: '0', marginBottom: '0.5rem' };
            case 'bottom':
                return { top: '100%', left: '0', marginTop: '0.5rem' };
            case 'left':
                return { right: '100%', top: '0', marginRight: '0.5rem' };
            case 'right':
                return { left: '100%', top: '0', marginLeft: '0.5rem' };
            default:
                return { top: '100%', left: '0', marginTop: '0.5rem' };
        }
    };

    return (
        <div 
            className={styles["timepicker-wrapper"]} 
            ref={ref} 
            style={{ position: 'relative', width: 'fit-content' }}
            onFocus={handleFocus}
            tabIndex={0}
        >
            <div 
                onClick={handleOpen} 
                className={`${styles["timepicker-field"]} ${isOpen ? styles.active : ""}`}
            >
                <div className={styles["placeholder-text"]}>
                    <div 
                        className={styles["label-text"]}
                        style={{
                            fontSize: time.length > 0 || isOpen ? '.75rem' : '.85rem',
                            transform: time.length > 0 || isOpen ? 'translate(0, -130%)' : 'translate(0)',
                            backgroundColor: time.length > 0 || isOpen ? 'var(--above-bg-db)' : 'transparent',
                            paddingInline: time.length > 0 || isOpen ? '.2rem' : '0',
                            color: isOpen ? 'var(--primary-color)' : 'var(--text-main)'
                        }}
                    >
                        {children}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {time || placeholder}
                </div>
            </div>
            {isOpen && (
                <div 
                    className={styles.popover}
                    style={getPopoverPosition()}
                >
                    <div className={styles["time-input-group"]}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <span className="material-symbols-outlined clickable" onClick={incrementHours}>keyboard_arrow_up</span>
                            <input 
                                type="text" 
                                value={hours} 
                                onChange={(e) => setHours(e.target.value)} 
                                onBlur={handleHoursBlur}
                                className={styles["time-input"]} 
                                placeholder="HH"
                                maxLength={2}
                                ref={hoursRef}
                                onKeyDown={handleTabClose}
                            />
                            <span className="material-symbols-outlined clickable" onClick={decrementHours}>keyboard_arrow_down</span>
                        </div>
                        <span>:</span>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <span className="material-symbols-outlined clickable" onClick={incrementMinutes}>keyboard_arrow_up</span>
                            <input 
                                type="text" 
                                value={minutes} 
                                onChange={(e) => setMinutes(e.target.value)} 
                                onBlur={handleMinutesBlur}
                                className={styles["time-input"]} 
                                placeholder="MM"
                                maxLength={2}
                                ref={minutesRef}
                                onKeyDown={handleTabClose}
                            />
                            <span className="material-symbols-outlined clickable" onClick={decrementMinutes}>keyboard_arrow_down</span>
                        </div>
                    </div>
                    <div className={styles["period-selector"]}>
                        <button 
                            className={`${styles["period-option"]} ${period === 'AM' ? styles.selected : ''}`}
                            onClick={() => setPeriod('AM')}
                            ref={periodAMRef}
                            onKeyDown={handleTabClose}
                            type='button'
                        >
                            AM
                        </button>
                        <button 
                            className={`${styles["period-option"]} ${period === 'PM' ? styles.selected : ''}`}
                            onClick={() => setPeriod('PM')}
                            ref={periodPMRef}
                            onKeyDown={handleTabClose}
                            type='button'
                        >
                            PM
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimePicker;