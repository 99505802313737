import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../useAuthContext';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();

    const signup = async (email, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/auth/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password})
        });
        
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            console.error(json.error);
        }
        if (response.ok) {
            localStorage.setItem('userAttendance', JSON.stringify(json.user));

            dispatch({ type: 'LOGIN', payload: json.user })

            setIsLoading(false);

            navigate('/signup/verify-email');
        }
    }
    return { signup, isLoading, error, setError }
}