import React, { useState } from "react";
import styles from './CSVUpload.module.css';

import Button from "../Button/Button";

const CSVUpload = ({ onUpload, children }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'text/csv') {
            setFile(selectedFile);
            setError('');
        } else {
            setError('Please upload a valid CSV file.');
        }
    };

    const handleUpload = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const emails = parseCSV(text);
                onUpload(emails);
            };
            reader.readAsText(file);
        }
    };

    const parseCSV = (text) => {
        const lines = text.split('\n');
        const header = lines[0].split(',').map(h => h.trim());
        const emailIndex = header.indexOf('email');
        const emailsIndex = header.indexOf('emails');

        if (emailIndex === -1 && emailsIndex === -1) {
            setError('CSV file must contain an "email" or "emails" header.');
            return [];
        }
    
        const columnIndex = emailIndex !== -1 ? emailIndex : emailsIndex;

        const emails = [];
        for (let i = 0; i < lines.length; i++) {
            const row = lines[i].split(',').map(item => item.trim());
            if (row[columnIndex]) {
                const email = row[columnIndex];
                if (validateEmail(email)) {
                    emails.push(email);
                }
            }
        }

        return emails;
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    return (
        <>
        <div className="form-group">
            <div className={styles.inputWrapper}>
                <input 
                    type="file" 
                    id="csv-upload"
                    accept=".csv" 
                    onChange={handleFileChange} 
                    className={styles.inputField} 
                />
                <label htmlFor="csv-upload" className={styles.placeholderText}>
                    {file ? file.name : children}
                </label>
                
            </div>
        {error && <p className="error">{error}</p>}
        </div>
        <Button design={"secondary"} type="button" disabled={!file} onClick={handleUpload} style={{width: "19rem"}}>Upload</Button>
        </>
    )
}

export default CSVUpload;