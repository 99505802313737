import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from '../useAuthContext';

export const useVerifyEmail = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { userAttendance, dispatch } = useAuthContext();
    const navigate = useNavigate();

    const verifyEmail = async (token, otp) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/auth/signup/verify-email', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token, otp})
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            // Update local storage
            const updatedUser = { ...userAttendance, isEmailVerified: true };
            localStorage.setItem('userAttendance', JSON.stringify(updatedUser));

            // Update Auth Context
            dispatch({type: 'LOGIN', payload: updatedUser});

            setIsLoading(false);

            navigate('/signup/onboarding');
        }
    }
    return { verifyEmail, isLoading, error, setError };
}