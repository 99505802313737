import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import { useAuthContext } from '../useAuthContext';
import { useCourseContext } from '../useCourseContext';

export const useDeleteCourse = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userAttendance, dispatch } = useAuthContext();
    const { userCourses, dispatchCourse } = useCourseContext();
    const navigate = useNavigate();

    const deleteCourse = async (courseId) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/course/delete', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userToken: userAttendance.token, courseId})
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            const removedCourse = json.course;

            dispatchCourse({ type: 'DELETE', payload: removedCourse._id });

            setIsLoading(false);

            navigate('/dashboard/courses')
        }
    }
    return { deleteCourse, isLoading, error, setError };
}