import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

import { CourseContextProvider } from './context/CourseContext';

// Pages

// Auth
import SignupPage from './pages/Auth/SignupPage';
import VerifyEmailPage from './pages/Auth/VerifyEmailPage';
import OnboardingPage from './pages/Auth/OnboardingPage';
import LoginPage from './pages/Auth/LoginPage';

// Dashboards
import DashboardPage from './pages/Dashboard/DashboardPage';
import CoursesPage from './pages/Dashboard/CoursesPage';
import CreateCoursePage from './pages/Dashboard/CreateCoursePage';
import EditCoursePage from './pages/Dashboard/EditCoursePage';

// Attendance
import CheckInPage from './pages/Attendance/CheckInPage';

function App() {
  const { userAttendance } = useAuthContext();

  return (
    <BrowserRouter>
      <Routes>
        {/* Auth */}
        <Route
          path='/signup'
          element={!userAttendance ? <SignupPage/> : <Navigate to={"/dashboard"}/>} />
        <Route
          path='/signup/verify-email'
          element={userAttendance ? <VerifyEmailPage/> : <Navigate to={"/signup"}/>} />
        <Route
          path='/signup/onboarding'
          element={userAttendance ? <OnboardingPage/> : <Navigate to={"/signup"}/>} />
        <Route
          path='/login'
          element={!userAttendance ? (<CourseContextProvider><LoginPage/></CourseContextProvider>) : <Navigate to={"/dashboard"}/>} />
        
        {/* Dashboard */}
        <Route 
          path='/dashboard'
          element={userAttendance ? (<CourseContextProvider><DashboardPage /></CourseContextProvider>) : <Navigate to={"/login"}/>} />
        <Route 
          path='/dashboard/courses'
          element={userAttendance ? (<CourseContextProvider><CoursesPage /></CourseContextProvider>) : <Navigate to={"/login"}/>} />
        <Route
          path='/dashboard/courses/create-course'
          element={userAttendance ? (<CourseContextProvider><CreateCoursePage/></CourseContextProvider>) : <Navigate to={"/login"}/>} />
        <Route
          path='/dashboard/courses/edit-course/:courseId'
          element={userAttendance ? (<CourseContextProvider><EditCoursePage/></CourseContextProvider>) : <Navigate to={"/login"}/>} />
        
        {/* Attendance */}
        <Route
          path='/attendance/:attendanceId/check-in/:date'
          element={<CheckInPage/>} />
        
        {/* Redirect unknown routes to signup */}
        <Route path="*" element={<Navigate to="/signup" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
