import React, { useState } from 'react';
import { useOnboarding } from '../../hooks/Auth/useOnboarding';
import { useAuthContext } from '../../hooks/useAuthContext';

// Components
import Button from '../../components/ui/Button/Button';
import Input from '../../components/ui/Input/Input';
import PhoneInput from '../../components/ui/PhoneInput/PhoneInput';
import Dropdown from '../../components/ui/Dropdown/Dropdown';

import coursiqLogo from '../../img/coursiqLogo.svg';

// Dropdown Options
const honorificOptions = [
    { text: 'Dr.', value: 'Dr.' },
    { text: 'Mr.', value: 'Mr.' },
    { text: 'Ms.', value: 'Ms.' },
    { text: 'Mrs.', value: 'Mrs.' },
    { text: 'Mx.', value: 'Mx.' },
];

const schoolLevelOptions = [
    { text: 'Preschool', value: 'Preschool' },
    { text: 'K-8', value: 'K-8' },
    { text: 'High School', value: 'High School' },
    { text: 'College/University', value: 'College/University' },
    { text: 'Technical School', value: 'Technical School' },
    { text: 'Adult Education', value: 'Adult Education' },
    { text: 'Other', value: 'Other' },
];

const jobTitleOptions = [
    { text: 'Teacher', value: 'Teacher' },
    { text: 'Professor', value: 'Professor' },
    { text: 'Lecturer', value: 'Lecturer' },
    { text: 'Dean', value: 'Dean' },
    { text: 'Vice Principal', value: 'Vice Principal' },
    { text: 'Principal', value: 'Principal' },
    { text: 'Superintendent', value: 'Superintendent' },
    { text: 'President', value: 'President' },
    { text: 'Other', value: 'Other' },
];

const OnboardingPage = () => {
    const [name, setName] = useState('');
    const [honorific, setHonorific] = useState('');
    const [phone, setPhone] = useState('');
    const [school, setSchool] = useState('');
    const [schoolLevel, setSchoolLevel] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const { onboarding, error, setError, isLoading } = useOnboarding();

    const handlePhoneChange = (number) => {
        setPhone(number);
    }

    const handleHonorificSelect = (option) => {
        setHonorific(option);
    }

    const handleSchoolLevelSelect = (option) => {
        setSchoolLevel(option);
    }

    const handleJobTitleSelect = (option) => {
        setJobTitle(option);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        await onboarding(name, honorific, phone, school, schoolLevel, jobTitle)
    }

    return (
        <div className="page">
            <div className="card">
                <img src={coursiqLogo} alt="Coursiq Logo" width={140} />
                <h2>Onboarding</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Input
                            type='text'
                            id='name'
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}>Name</Input>
                    </div>
                    <div className="form-group">
                        <Dropdown options={honorificOptions} onSelect={handleHonorificSelect} placeholder='Honorific' />
                    </div>
                    <div className="form-group">
                        <PhoneInput 
                            pinnedCountries={['us', 'ca', 'gb']}
                            searchable
                            onChange={handlePhoneChange} />
                    </div>
                    <div className="form-group">
                        <Input
                            type='text'
                            id='school'
                            name='school'
                            value={school}
                            onChange={(e) => setSchool(e.target.value)}>School</Input>
                    </div>
                    <div className="form-group">
                        <Dropdown options={schoolLevelOptions} onSelect={handleSchoolLevelSelect} placeholder='School Level' />
                    </div>
                    <div className="form-group">
                        <Dropdown options={jobTitleOptions} onSelect={handleJobTitleSelect} placeholder='Job Title' />
                    </div>
                    <Button design={"primary"} type="submit" disabled={isLoading} style={{width: "100%"}}>Continue</Button>
                </form>
            </div>
            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
            <p className="copyright">&copy;{new Date().getFullYear()} Connxting LLC. All rights reserved</p>
        </div>
    )
}

export default OnboardingPage;