import React, { useState, useEffect } from 'react';
import { useVerifyEmail } from '../../hooks/Auth/useVerifyEmail';
import { useAuthContext } from '../../hooks/useAuthContext';

// Components
import OtpInput from '../../components/ui/OtpInput/OtpInput';
import Button from '../../components/ui/Button/Button';

import coursiqLogo from '../../img/coursiqLogo.svg';

const VerifyEmailPage = () => {
    const [otp, setOtp] = useState('')
    const { verifyEmail, error, setError, isLoading } = useVerifyEmail();
    const { userAttendance } = useAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (otp.length < 6) {
            setError('Verification code must be 6 digits.');
            return;
        }

        await verifyEmail(userAttendance.token, otp);
    }

    return (
        <div className="page">
            <div className="card">
                <img src={coursiqLogo} alt="Coursiq Logo" width={140}/>
                <h2>Verify Email</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <OtpInput
                            length={6}
                            valueType='numeric'
                            onChange={setOtp} />
                        <p>Enter the code sent to your email.</p>
                    </div>
                    <Button design={"primary"} type="submit" disabled={isLoading} style={{width: "100%"}}>Verify Email</Button>
                </form>
            </div>
            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
            <p className="copyright">&copy;{new Date().getFullYear()} Connxting LLC. All rights reserved</p>
        </div>
    )
}

export default VerifyEmailPage;