import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../useAuthContext";
import { useCourseContext } from "../useCourseContext";

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { dispatch } = useAuthContext();
    const { dispatchCourse } = useCourseContext();
    const navigate = useNavigate();

    const login = async (email, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password}),
        })

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            dispatch({ type: 'LOGIN', payload: json.user });

            const { courses } = json;
            courses.forEach(course => {
                dispatchCourse({ type: 'ADD', payload: course });
            })

            setIsLoading(false);

            navigate('/dashboard');
        }
    };
    return { login, isLoading, error, setError };
}