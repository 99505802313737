import React, { useState, useEffect, useRef } from 'react';
import './OtpInput.modules.css';

const OTPInput = ({
  length,
  valueType = 'numeric', // 'numeric' or 'alphanumeric'
  dividers = [], // array of indices where dividers should be placed
  onChange
}) => {
  const [values, setValues] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    let newValue = e.target.value;
    if (valueType === 'numeric' && !/^\d*$/.test(newValue)) return;
    if (valueType === 'alphanumeric' && !/^[a-zA-Z0-9]*$/.test(newValue)) return;
    if (valueType === 'alphanumeric') newValue = newValue.toUpperCase();

    const newValues = [...values];
    newValues[index] = newValue.slice(-1); // keep only the last entered character
    setValues(newValues);
    onChange(newValues.join(''));

    if (newValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newValues = [...values];
      if (newValues[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
          newValues[index - 1] = '';
        }
      } else {
        newValues[index] = '';
      }
      setValues(newValues);
      onChange(newValues.join(''));
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (valueType === 'numeric' && !/^\d*$/.test(paste)) return;
    if (valueType === 'alphanumeric' && !/^[a-zA-Z0-9]*$/.test(paste)) return;

    const pasteValues = paste.split('').slice(0, length);
    const newValues = [...values];
    pasteValues.forEach((char, i) => {
      if (valueType === 'numeric' && !/^\d*$/.test(char)) return;
      newValues[i] = char;
    });
    setValues(newValues);
    onChange(newValues.join(''));
    setTimeout(() => {
      if (pasteValues.length < length) {
        inputRefs.current[pasteValues.length].focus();
      } else {
        inputRefs.current[length - 1].focus();
      }
    }, 0);
  };

  const getNextEditableIndex = () => {
    for (let i = 0; i < values.length; i++) {
      if (values[i] === '') return i;
    }
    return length - 1;
  };

  const getLastFilledIndex = () => {
    for (let i = length - 1; i >= 0; i--) {
      if (values[i] !== '') return i;
    }
    return 0;
  };

  useEffect(() => {
    const nextIndex = getNextEditableIndex();
    const lastIndex = getLastFilledIndex();
    inputRefs.current[nextIndex !== lastIndex ? nextIndex : lastIndex].focus();
  }, [values]);

  return (
    <div className="otp-input-container">
      {values.map((value, index) => (
        <React.Fragment key={index}>
          <OTPInputItem
            ref={(el) => (inputRefs.current[index] = el)}
            id={`otp-input-${index}`}
            value={value}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            disabled={index !== getNextEditableIndex() && index !== getLastFilledIndex()}
            inputMode={valueType === 'numeric' ? 'numeric' : 'text'}
          />
          {dividers.includes(index) && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
};

const OTPInputItem = React.forwardRef(({ id, value, onChange, onKeyDown, onPaste, disabled, inputMode }, ref) => (
  <input
    ref={ref}
    id={id}
    className="otp-input-item"
    type="text"
    value={value}
    onChange={onChange}
    onKeyDown={onKeyDown}
    onPaste={onPaste}
    maxLength={1}
    disabled={disabled}
    inputMode={inputMode}
  />
));

const Divider = () => <div className="otp-divider">-</div>;

export default OTPInput;
