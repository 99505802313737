import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../useAuthContext";
import { useCourseContext } from "../useCourseContext";

export const useLogout = () => {
    const { dispatch } = useAuthContext();
    const { dispatchCourse } = useCourseContext();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('userAttendance');
        localStorage.removeItem('userCourses');

        dispatch({type: 'LOGOUT'});
        dispatchCourse({type: 'REMOVE'});

        navigate('/login');
    }
    return { logout };
}