import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCheckIn } from '../../hooks/Attendance/useCheckIn';

// Components
import Input from '../../components/ui/Input/Input';
import Button from '../../components/ui/Button/Button';

const CheckInPage = () => {
    const [email, setEmail] = useState('');
    const [course, setCourse] = useState(null);
    const [attendance, setAttendance] = useState(null);
    const [instructor, setInstructor] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(null);
    const { attendanceId, date } = useParams();
    const { checkIn, successMessage, isLoading, errorCheckIn, setErrorCheckIn } = useCheckIn();

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const response = await fetch(`/api/course/attendance/${attendanceId}`);
                const data = await response.json();

                if (response.ok) {
                    setCourse(data.course);
                    setAttendance(data.attendance);
                    setInstructor(data.instructor);
                } else {
                    setError(data.error || 'Failed to fetch course data');
                }
            } catch (error) {
                setError('Failed to fetch course data');
            } finally {
                setLoading(false);
            }
        };

        fetchCourseData();
    }, [attendanceId]);

    useEffect(() => {
        const requestLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocation(`${position.coords.latitude}, ${position.coords.longitude}`);
                    },
                    (error) => {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                setError('Location access was denied. Please enable location access in your browser settings.');
                                break;
                            case error.POSITION_UNAVAILABLE:
                                setError('Location information is unavailable.');
                                break;
                            case error.TIMEOUT:
                                setError('The request to get user location timed out.');
                                break;
                            default:
                                setError('An unknown error occurred.');
                                break;
                        }
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0,
                    }
                );
            } else {
                setError('Geolocation is not supported by this browser.');
            }
        };

        requestLocation();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!location) {
            setError('Location is required for check-in.');
            return;
        }

        const checkInTime = new Date().toISOString();

        await checkIn(attendanceId, course._id, email, location, checkInTime);
    };

    const getAbbreviatedDays = (days = []) => {
        const dayAbbreviations = {
            Sunday: 'Su',
            Monday: 'Mo',
            Tuesday: 'Tu',
            Wednesday: 'We',
            Thursday: 'Th',
            Friday: 'Fr',
            Saturday: 'Sa'
        };

        if (days.length === 7) {
            return 'Everyday';
        }
        if (days[0] === 'Sunday' && days[1] === 'Saturday') {
            return 'Weekends';
        }
        if (days[0] === 'Monday' && days[4] === 'Friday' && days.length === 5) {
            return 'Weekdays';
        }
        return days.map(day => dayAbbreviations[day]).join('');
    };

    return (
        <div className='page'>
            {!loading ? (
                <>
                <div className="card">
                    <div style={{marginBottom: "0.5rem"}}>
                        <h2>Check in for <span style={{color: "var(--primary-color)"}}>{course.courseAbbreviation} {course.courseNumber}</span></h2>
                        <p>With {instructor.honorific} {instructor.name}</p>
                        <p>{getAbbreviatedDays(course.daysOfWeek)} {course.startTime}-{course.endTime}</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <Input
                                type='email'
                                id='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} >University Email</Input>
                        </div>
                        <Button design={"primary"} type="submit" style={{width: "100%"}}>Check In</Button>
                    </form>
                </div>
                {errorCheckIn && <div className='message error'><strong>Error:</strong>&nbsp;{errorCheckIn}</div>}
                {successMessage && <div className='message success'><strong>Success!</strong>&nbsp;You have successfully checked in.</div>}
                </>
            ) : (
                <h1>Loading...</h1>
            )}
            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error}</div>}
            <p className="copyright">&copy;{new Date().getFullYear()} Connxting LLC. All rights reserved</p>
        </div>
    );
};

export default CheckInPage;
