import React from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

// Components
import Dashboard from "../../components/blocks/Dashboard/Dashboard";

const DashboardPage = () => {
    const { userAttendance, dispatch } = useAuthContext();
    return (
        <Dashboard
            activePage={"dashboard"}
            profilePhoto={userAttendance.profilePhoto}
            userName={userAttendance.name}
            userPrefix={userAttendance.prefix} >
            <h2 style={{marginTop: "0"}}>Dashboard</h2>
        </Dashboard>
    )
};

export default DashboardPage;