import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCourseContext } from "../../hooks/useCourseContext";
import { useDeleteCourse } from "../../hooks/Courses/useDeleteCourse";
import { useCreateAttendance } from "../../hooks/Attendance/useCreateAttendance";

// Components
import Dashboard from "../../components/blocks/Dashboard/Dashboard";
import Button from "../../components/ui/Button/Button";
import Modal from "../../components/ui/Modal/Modal";

const CoursesPage = () => {
    const [courseToDelete, setCourseToDelete] = useState(null); // Track the course to be deleted
    const [qrCodeModal, setQrCodeModal] = useState(false);
    const { userAttendance } = useAuthContext();
    const { userCourses } = useCourseContext();
    const { deleteCourse, isLoading } = useDeleteCourse();
    const { createAttendance, code, link, isLoadingAttendance, error, setError } = useCreateAttendance();
    const navigate = useNavigate();

    const openModal = (course) => {
        setCourseToDelete(course);
    };

    const closeModal = () => {
        setCourseToDelete(null);
    };

    const openQrModal = () => {
        setQrCodeModal(true);
    }

    const closeQrModal = () => {
        setQrCodeModal(false);
    }

    const handleDeleteCourse = async () => {
        if (courseToDelete) {
            await deleteCourse(courseToDelete._id);
            closeModal();
        }
    };

    const handleCreateAttendance = async (courseId) => {
        await createAttendance(courseId);
        setQrCodeModal(true);
    }

    const handleDownloadQRCode = () => {
        const linkElement = document.createElement('a');
        linkElement.href = code; // The base64 data URL for the QR code image
        linkElement.download = 'attendance_qr_code.png'; // File name for download
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link).then(() => {
            alert("Link copied to clipboard!");
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const getAbbreviatedDays = (days = []) => {
        const dayAbbreviations = {
            Sunday: 'Su',
            Monday: 'Mo',
            Tuesday: 'Tu',
            Wednesday: 'We',
            Thursday: 'Th',
            Friday: 'Fr',
            Saturday: 'Sa'
        };

        if (days.length === 7) {
            return 'Everyday';
        }
        if (days[0] === 'Sunday' && days[1] === 'Saturday') {
            return 'Weekends';
        }
        if (days[0] === 'Monday' && days[4] === 'Friday' && days.length === 5) {
            return 'Weekdays';
        }
        return days.map(day => dayAbbreviations[day]).join('');
    };

    return (
        <Dashboard
            activePage={"courses"}
            profilePhoto={userAttendance.profilePhoto}
            userName={userAttendance.name}
            userPrefix={userAttendance.prefix} >
            <div style={{display: "flex", width: '100%', justifyContent: "space-between", alignItems: "center"}}>
                <h2 style={{marginTop: "0"}}>Courses</h2>
                <Button
                    design={"primary"}
                    onClick={() => navigate('/dashboard/courses/create-course')}
                >
                    <span className="material-symbols-outlined" style={{fontSize: "18px"}}>add_circle</span>Create Course
                </Button>
            </div>
            
            <div>
                {userCourses.length > 0 ? (
                    userCourses.map(course => (
                    <div className="course-card" key={course._id}>
                        <div className="inline-group">
                            <h3><a href={`/dashboard/courses/edit-course/${course._id}`}>{course.courseTitle || "No Title"}</a></h3>
                            <span 
                                className="material-symbols-outlined clickable" 
                                onClick={() => openModal(course)} 
                                style={{fontSize: "20px"}}
                            >
                                delete
                            </span>
                        </div>
                        <h4 style={{color: "var(--primary-color)"}}>{course.courseAbbreviation || 'n/a'} {course.courseNumber || 'n/a'}</h4>
                        <p>{(course.daysOfWeek && getAbbreviatedDays(course.daysOfWeek)) || "No Days"} {course.startTime ? course.startTime : "No Start Time"} - {course.endTime || "No End Time"}</p>
                        <div className="inline-group">
                            <p><span style={{color: "var(--primary-color)", fontWeight: "bold"}}>{course.students.length || "No enrollment"}</span> students</p>
                            <Button design={"secondary"} type="button" style={{padding: ".25rem .5rem"}} onClick={() => handleCreateAttendance(course._id)}>Get QR Code</Button>
                        </div>
                    </div>
                    )) 
                ) : (
                    <div>
                        <p>No courses found.</p>
                        <Button
                            design={"primary"}
                            onClick={() => navigate('/dashboard/courses/create-course')}
                        >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>add_circle</span>Create Course
                        </Button>
                    </div>
                )}
            </div>

            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}

            {courseToDelete && (
                <Modal
                    isOpen={!!courseToDelete}
                    onClose={closeModal} 
                >
                    <h3>Are you sure you want to delete <span style={{color: "var(--primary-color)"}}>{courseToDelete.courseTitle || "this course"}</span>?</h3>
                    <p>This is permanent and cannot be undone.</p>
                    <div className="inline-group" style={{justifyContent: "flex-start", gap: ".5rem"}}>
                        <Button
                            onClick={handleDeleteCourse}
                            type="button"
                            design={"danger"}
                            disabled={isLoading}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={closeModal}
                            type="button"
                            design={"neutral"}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            )}

            {qrCodeModal && (
                <Modal
                    isOpen={qrCodeModal}
                    onClose={closeQrModal} >
                    <h3>QR Code</h3>
                    <img src={code} alt="Attendance QR Code" />
                    <p>{link}</p>
                    <div className="inline-group" style={{justifyContent: "flex-start", gap: "1rem"}}>
                        <Button 
                            onClick={handleDownloadQRCode}
                            design={"primary"} 
                            type="button"
                            style={{padding: ".5rem 1rem"}} >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>download</span>Download QR Code</Button>
                        <Button 
                            onClick={handleCopyLink}
                            design={"secondary"} 
                            type="button"
                            style={{padding: ".5rem 1rem"}} >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>content_copy</span>Copy Link</Button>
                    </div>
                </Modal>
            )}
        </Dashboard>
    );
};

export default CoursesPage;