import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import { useAuthContext } from '../useAuthContext';
import { useCourseContext } from '../useCourseContext';

export const useCheckIn = () => {
    const [errorCheckIn, setErrorCheckIn] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const navigate = useNavigate();

    const checkIn = async (attendanceId, courseId, studentEmail, location, checkInTime) => {
        setIsLoading(true);
        setErrorCheckIn(null);
        setSuccessMessage(null);

        const response = await fetch('/api/course/attendance/check-in', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ attendanceId, courseId, studentEmail, location, checkInTime })
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setErrorCheckIn(json.error);
            console.error(json.error)
        }
        if (response.ok) {
            setIsLoading(false);

            setSuccessMessage("Successfully checked-in!");
            // console.log("success")
        }
    }
    return { checkIn, successMessage, isLoading, errorCheckIn, setErrorCheckIn };
}