import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCourseContext } from '../../hooks/useCourseContext';
import { useEditCourse } from '../../hooks/Courses/useEditCourse';

// Components
import Dashboard from '../../components/blocks/Dashboard/Dashboard';
import InputDb from '../../components/ui/InputDb/InputDb';
import Dropdown from '../../components/ui/Dropdown/Dropdown';
import Button from '../../components/ui/Button/Button';
import DatePicker from '../../components/ui/DatePicker/DatePicker';
import TimePicker from '../../components/ui/TimePicker/TimePicker';
import CSVUpload from '../../components/ui/CSVUpload/CSVUpload';
import Modal from '../../components/ui/Modal/Modal';
import Tooltip from '../../components/ui/Tooltip/Tooltip';

const timezoneOptions = [
    { text: "Hawaii-Aleutian Standard Time", value: "Pacific/Honolulu" },
    { text: "Alaska Standard Time", value: "America/Anchorage" },
    { text: "Pacific Standard Time (US & Canada)", value: "America/Los_Angeles" },
    { text: "Mountain Standard Time (US & Canada)", value: "America/Denver" },
    { text: "Arizona (No DST)", value: "America/Phoenix" },
    { text: "Central Standard Time (US & Canada)", value: "America/Chicago" },
    { text: "Eastern Standard Time (US & Canada)", value: "America/New_York" },
    { text: "Atlantic Standard Time", value: "America/Halifax" },
    { text: "Puerto Rico and US Virgin Islands Time", value: "America/Puerto_Rico" },
    { text: "American Samoa Time", value: "Pacific/Pago_Pago" },
    { text: "Baker Island Time", value: "Etc/GMT+12" },
];

const EditCoursePage = () => {
    const { courseId } = useParams();
    const { userAttendance, dispatch } = useAuthContext();
    const { userCourses, dispatchCourse } = useCourseContext();
    const { editCourse, isLoading, error, setError } = useEditCourse();

    const course = userCourses.find(c => c._id === courseId);

    const [title, setTitle] = useState(course.courseTitle);
    const [abbreviation, setAbbreviation] = useState(course.courseAbbreviation);
    const [number, setNumber] = useState(course.courseNumber);
    const [location, setLocation] = useState(course.location);
    const [days, setDays] = useState(course.daysOfWeek);
    const [timezone, setTimezone] = useState(course.timezone);
    const [startTime, setStartTime] = useState(course.startTime);
    const [endTime, setEndTime] = useState(course.endTime);
    const [enrollment, setEnrollment] = useState(course.students.length);
    const [students, setStudents] = useState(course.students);
    const [isStudentsOpen, setIsStudentsOpen] = useState(false);
    const [isAllStudentsOpen, setIsAllStudentsOpen] = useState(false);
    const [uploadedStudents, setUploadedStudents] = useState([]);

    const handleCSVUpload = (emails) => {
        const combinedStudents = [...students, ...emails];
        const uniqueStudents = Array.from(new Set(combinedStudents));
    
        setStudents(uniqueStudents);
        setUploadedStudents(emails);
        setEnrollment(uniqueStudents.length);
    };
    
    const handleRemoveStudent = (studentToRemove) => {
        const updatedStudents = students.filter(student => student !== studentToRemove);
        const updatedUploadedStudents = uploadedStudents.filter(student => student !== studentToRemove);
        
        const uniqueStudents = Array.from(new Set(updatedStudents));
        const uniqueUploadedStudents = Array.from(new Set(updatedUploadedStudents));
        
        setStudents(uniqueStudents);
        setUploadedStudents(uniqueUploadedStudents);
        setEnrollment(uniqueStudents.length);
    };  

    const getAbbreviatedDays = (days = []) => {
        const dayAbbreviations = {
            Sunday: 'Su',
            Monday: 'Mo',
            Tuesday: 'Tu',
            Wednesday: 'We',
            Thursday: 'Th',
            Friday: 'Fr',
            Saturday: 'Sa'
        };

        if (days.length === 7) {
            return 'Everyday';
        }
        if (days[0] === 'Sunday' && days[1] === 'Saturday') {
            return 'Weekends';
        }
        if (days[0] === 'Monday' && days[4] === 'Friday' && days.length === 5) {
            return 'Weekdays';
        }
        return days.map(day => dayAbbreviations[day]).join('');
    };

    const handleSelectTimezone = (option) => {
        setTimezone(option);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        await editCourse(courseId, title, abbreviation, number, location, days, timezone, startTime, endTime, students);
    }
    
    return (
        <Dashboard
            activePage={"courses"}
            profilePhoto={userAttendance.profilePhoto || null}
            userName={userAttendance.name}
            userPrefix={userAttendance.prefix} >
            <h2 style={{marginTop: "0"}}>Edit Course</h2>
            <div className="twocol">
                <div className="leftcol">
                <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='title'
                                id='title'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}>Course Title</InputDb>
                        </div>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='abbreviation'
                                id='abbreviation'
                                value={abbreviation}
                                onChange={(e) => setAbbreviation(e.target.value)}>Course Abbreviation</InputDb>
                        </div>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='number'
                                id='number'
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}>Course Number</InputDb>
                        </div>
                        <div className="form-group">
                            <Tooltip 
                                content={`Coordinates formatted as "latitude, longitude"`}
                                direction='right'>
                                <InputDb
                                    type='text'
                                    name='location'
                                    id='location'
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}>Course Location</InputDb>
                            </Tooltip>
                        </div>
                        <div className="form-group">
                            <DatePicker 
                                popoverSide='right'
                                value={days}
                                onChange={setDays}>Days of Week</DatePicker>
                        </div>
                        <div className="form-group">
                            <Dropdown
                                options={timezoneOptions} onSelect={handleSelectTimezone} placeholder='Timezone' initializedValue={timezone} />
                        </div>
                        <div className="form-group">
                            <TimePicker
                                value={startTime}
                                onChange={setStartTime}
                                popoverSide='right'>Start Time</TimePicker>
                        </div>
                        <div className="form-group">
                            <TimePicker
                                value={endTime}
                                onChange={setEndTime}
                                popoverSide='right'>End Time</TimePicker>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: ".5rem"}}>
                            <h3>Add Students</h3>
                            <Tooltip content={`Upload a .csv file with a column titled "email" or "emails" with your student's emails`}
                            direction='right'><span className='material-symbols-outlined' style={{fontSize: "20px"}}>info</span></Tooltip>
                        </div>
                        <div className="form-group">
                            <CSVUpload onUpload={handleCSVUpload}><span className='material-symbols-outlined' style={{fontSize: "20px"}}>upload_file</span>Upload Student List</CSVUpload>
                            {uploadedStudents.length > 0 && <p>Successfully uploaded <a onClick={() => setIsStudentsOpen(true)}style={{cursor: "pointer", textDecoration: "underline"}}>{uploadedStudents.length} students</a>.</p>}
                        </div>
                        <Modal 
                            isOpen={isStudentsOpen}
                            onClose={() => setIsStudentsOpen(false)} >
                            <h4>Uploaded Students</h4>
                            <ul>{uploadedStudents.map(student => 
                                <>
                                    <li style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>{student}
                                    <span 
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleRemoveStudent(student)}>&times;</span></li>
                                </>)}
                            </ul></Modal>
                        <Button 
                            design={'primary'} 
                            type="submit"
                            disabled={isLoading}
                            style={{width: "19rem"}} >Save Changes</Button>
                    </form>
                </div>
                <div className="rightcol">
                    <div className="course-card" key={course._id}>
                        <h3>{title || "No Title"}</h3>
                        <h4 style={{color: "var(--primary-color)"}}>{abbreviation || 'n/a'} {number || 'n/a'}</h4>
                        <p>{(days && getAbbreviatedDays(days)) || "No Days"} {startTime ? startTime : "No Start Time"} - {endTime || "No End Time"}</p>
                        <a onClick={() => setIsAllStudentsOpen(true)} style={{cursor: "pointer", textDecoration: "underline"}}>{enrollment || "No enrollment"} students</a>
                    </div>
                    {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
                    <Modal 
                        isOpen={isAllStudentsOpen}
                        onClose={() => setIsAllStudentsOpen(false)} >
                        <h4>All Students</h4>
                        <p>This list includes previous students and uploaded students</p>
                        <ul>{students.map(student => 
                            <>
                                <li style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>{student}
                                <span 
                                    style={{cursor: "pointer"}}
                                    onClick={() => handleRemoveStudent(student)}>&times;</span></li>
                            </>)}
                        </ul></Modal>
                </div>
            </div>
        </Dashboard>
    )
}

export default EditCoursePage;