import React, { useState } from "react";
import { useLogout } from '../../../hooks/Auth/useLogout';
import { useNavigate } from "react-router-dom";
import './ProfileDropdown.modules.css';

// Components
import Tooltip from "../Tooltip/Tooltip";

const ProfileDropdown = ({photo, name, prefix, ...rest}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { logout } = useLogout();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    return (
        <div className="profile-dropdown-container">
            <div className="profile-dropdown" onClick={toggleDropdown}>
                <img src={photo ? `localhost:8080/uploads${photo}` : "https://via.placeholder.com/30x30"} alt={name ? `${name}'s Profile Photo` : "Profile Photo"} className="profile-pic" />
                <div className="column-text">
                    <strong>{prefix || ""} {name || "Error"}</strong>
                    <div style={{fontSize: '.7rem'}}>My Account</div>
                </div>
                {!showDropdown ? (
                    <span className="material-symbols-outlined">keyboard_arrow_down</span>
                ) : (
                    <span className="material-symbols-outlined">keyboard_arrow_up</span>
                )}
            </div>
            {showDropdown && (
                <div className="profile-dropdown-menu">
                    <ul className="profile-dropdown-choices">
                        <li><Tooltip direction="left" content={"Coming soon..."} style={{display: "flex", gap: ".5rem"}}><span className="material-symbols-outlined">manage_accounts</span>Account Settings</Tooltip></li>
                        <li onClick={() => navigate('/dashboard/courses')}><span className="material-symbols-outlined">school</span>Your Courses</li>
                        <li><Tooltip direction="left" content={"Coming soon..."} style={{display: "flex", gap: ".5rem"}}><span className="material-symbols-outlined">groups</span>Attendance</Tooltip></li>
                        <li onClick={logout}><span className="material-symbols-outlined">logout</span>Sign Out</li>
                        <hr style={{width: "90%"}}/>
                        <p style={{margin: "0", paddingInline: ".5rem", paddingBottom: ".5rem", fontSize: ".75rem"}}>v0.2.0-alpha 9/15/2024<br/>&copy; Connxting LLC.</p>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default ProfileDropdown;