import React, { useState, useEffect, useRef } from 'react';
import styles from './DatePicker.module.css';

const DatePicker = ({ popoverSide = 'bottom', children, placeholder, value = [], onChange, ...rest }) => {
    const daysOfWeek = [
        { full: 'Sunday', abbr: 'Su' },
        { full: 'Monday', abbr: 'Mo' },
        { full: 'Tuesday', abbr: 'Tu' },
        { full: 'Wednesday', abbr: 'We' },
        { full: 'Thursday', abbr: 'Th' },
        { full: 'Friday', abbr: 'Fr' },
        { full: 'Saturday', abbr: 'Sa' }
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedDays, setSelectedDays] = useState(
        Array.isArray(value) ? value.map(day => daysOfWeek.find(d => d.full === day)) : []
    );

    const ref = useRef(null);
    const firstDayRef = useRef(null);
    const lastDayRef = useRef(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        if (Array.isArray(value) && !isInitialized.current) {
            setSelectedDays(value.map(day => daysOfWeek.find(d => d.full === day)));
            isInitialized.current = true;
        }
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        const handleFocusOutside = (event) => {
            if (isOpen && ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('focusin', handleFocusOutside);
        return () => {
            document.removeEventListener('focusin', handleFocusOutside);
        };
    }, [isOpen]);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleFocus = () => {
        if (!isOpen) {
            handleOpen();
        }
    };

    const handleTabClose = (event) => {
        if (event.key === 'Tab') {
            if (event.shiftKey) {
                // Back-tabbing
                if (event.target === firstDayRef.current) {
                    setIsOpen(false);
                }
            } else {
                // Forward-tabbing
                if (event.target === lastDayRef.current) {
                    setIsOpen(false);
                }
            }
        }
    };

    // Define handleKeyDown function to handle Enter and Tab key events
    const handleKeyDown = (event, day) => {
        if (event.key === 'Enter') {
            toggleDay(day);
        } else if (event.key === 'Tab') {
            handleTabClose(event);
        }
    };

    const toggleDay = (day) => {
        const isSelected = selectedDays.some(d => d.abbr === day.abbr);
        let newSelectedDays = isSelected
            ? selectedDays.filter(d => d.abbr !== day.abbr)
            : [...selectedDays, day];

        newSelectedDays = daysOfWeek.filter(d => newSelectedDays.some(selected => selected.full === d.full));
        setSelectedDays(newSelectedDays);

        if (onChange) {
            onChange(newSelectedDays.map(d => d.full));
        }
    };

    const getPopoverPosition = () => {
        switch (popoverSide) {
            case 'top':
                return { bottom: '100%', left: '0', marginBottom: '0.5rem' };
            case 'bottom':
                return { top: '100%', left: '0', marginTop: '0.5rem' };
            case 'left':
                return { right: '100%', top: '0', marginRight: '0.5rem' };
            case 'right':
                return { left: '100%', top: '0', marginLeft: '0.5rem' };
            default:
                return { top: '100%', left: '0', marginTop: '0.5rem' };
        }
    };

    return (
        <div 
            className={styles["datepicker-wrapper"]} 
            ref={ref} 
            style={{ position: 'relative', width: 'fit-content' }}
            onFocus={handleFocus}
            tabIndex={0}
        >
            <div 
                onClick={handleOpen} 
                className={`${styles["datepicker-field"]} ${isOpen ? styles.active : ""}`}
            >
                <div className={styles["placeholder-text"]}>
                    <div 
                        className={styles["label-text"]}
                        style={{
                            fontSize: selectedDays.length > 0 || isOpen ? '.75rem' : '.85rem',
                            transform: selectedDays.length > 0 || isOpen ? 'translate(0, -130%)' : 'translate(0)',
                            backgroundColor: selectedDays.length > 0 || isOpen ? 'var(--above-bg-db)' : 'transparent',
                            paddingInline: selectedDays.length > 0 || isOpen ? '.2rem' : '0',
                            color: isOpen ? 'var(--primary-color)' : 'var(--text-main)'
                        }}
                    >
                        {children}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    {selectedDays.length > 0 ? selectedDays.map(day => day.abbr.substring(0, 2)).join('') : placeholder}
                </div>
            </div>
            {isOpen && (
                <div 
                    className={styles.popover}
                    style={getPopoverPosition()}
                >
                    {daysOfWeek.map((day, index) => (
                        <div
                            key={day.full}
                            onClick={() => toggleDay(day)}
                            className={`${styles["popover-option"]} ${selectedDays.some(d => d.abbr === day.abbr) ? styles.selected : ''}`}
                            ref={index === 0 ? firstDayRef : index === daysOfWeek.length - 1 ? lastDayRef : null}
                            onKeyDown={(event) => handleKeyDown(event, day)}
                            tabIndex={0}
                        >
                            {day.abbr}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DatePicker;
