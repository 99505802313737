import React, { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCreateCourse } from '../../hooks/Courses/useCreateCourse';

// Components
import Dashboard from '../../components/blocks/Dashboard/Dashboard';
import InputDb from '../../components/ui/InputDb/InputDb';
import Dropdown from '../../components/ui/Dropdown/Dropdown';
import Button from '../../components/ui/Button/Button';
import DatePicker from '../../components/ui/DatePicker/DatePicker';
import TimePicker from '../../components/ui/TimePicker/TimePicker';
import CSVUpload from '../../components/ui/CSVUpload/CSVUpload';
import Modal from '../../components/ui/Modal/Modal';
import Tooltip from '../../components/ui/Tooltip/Tooltip';

const timezoneOptions = [
    { text: "Hawaii-Aleutian Standard Time", value: "Pacific/Honolulu" },
    { text: "Alaska Standard Time", value: "America/Anchorage" },
    { text: "Pacific Standard Time (US & Canada)", value: "America/Los_Angeles" },
    { text: "Mountain Standard Time (US & Canada)", value: "America/Denver" },
    { text: "Arizona (No DST)", value: "America/Phoenix" },
    { text: "Central Standard Time (US & Canada)", value: "America/Chicago" },
    { text: "Eastern Standard Time (US & Canada)", value: "America/New_York" },
    { text: "Atlantic Standard Time", value: "America/Halifax" },
    { text: "Puerto Rico and US Virgin Islands Time", value: "America/Puerto_Rico" },
    { text: "American Samoa Time", value: "Pacific/Pago_Pago" },
    { text: "Baker Island Time", value: "Etc/GMT+12" },
];

const CreateCoursePage = () => {
    const [title, setTitle] = useState('');
    const [abbreviation, setAbbreviation] = useState('');
    const [number, setNumber] = useState('');
    const [location, setLocation] = useState('');
    const [days, setDays] = useState('');
    const [timezone, setTimezone] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [enrollment, setEnrollment] = useState('');
    const [students, setStudents] = useState([]);
    const [isStudentsOpen, setIsStudentsOpen] = useState(false);
    const { userAttendance, dispatch } = useAuthContext();
    const { createCourse, isLoading, error, setError } = useCreateCourse();

    const handleCSVUpload = (emails) => {
        const combinedStudents = [...students, ...emails];
        const uniqueStudents = Array.from(new Set(combinedStudents));
    
        setStudents(uniqueStudents);
        setEnrollment(uniqueStudents.length);
    };

    const handleRemoveStudent = (studentToRemove) => {
        const updatedStudents = students.filter(student => student !== studentToRemove);
        const uniqueStudents = Array.from(new Set(updatedStudents));

        setStudents(uniqueStudents);
        setEnrollment(uniqueStudents.length);
    };    

    const handleSelectTimezone = (option) => {
        setTimezone(option);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        await createCourse(title, abbreviation, number, location, days, timezone, startTime, endTime, students);
    }

    const getAbbreviatedDays = (days = []) => {
        const dayAbbreviations = {
            Sunday: 'Su',
            Monday: 'Mo',
            Tuesday: 'Tu',
            Wednesday: 'We',
            Thursday: 'Th',
            Friday: 'Fr',
            Saturday: 'Sa'
        };

        if (days.length === 7) {
            return 'Everyday';
        }
        if (days[0] === 'Sunday' && days[1] === 'Saturday') {
            return 'Weekends';
        }
        if (days[0] === 'Monday' && days[4] === 'Friday' && days.length === 5) {
            return 'Weekdays';
        }
        return days.map(day => dayAbbreviations[day]).join('');
    };

    return (
        <Dashboard
            activePage={"courses"}
            profilePhoto={userAttendance.profilePhoto || null}
            userName={userAttendance.name}
            userPrefix={userAttendance.prefix} >
            <h2 style={{marginTop: "0"}}>Create Course</h2>
            <div className="twocol">
                <div className="leftcol">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='title'
                                id='title'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}>Course Title</InputDb>
                        </div>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='abbreviation'
                                id='abbreviation'
                                value={abbreviation}
                                onChange={(e) => setAbbreviation(e.target.value)}>Course Abbreviation</InputDb>
                        </div>
                        <div className="form-group">
                            <InputDb
                                type='text'
                                name='number'
                                id='number'
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}>Course Number</InputDb>
                        </div>
                        <div className="form-group">
                            <Tooltip 
                                content={`Coordinates formatted as "latitude, longitude"`}
                                direction='right'>
                                <InputDb
                                    type='text'
                                    name='location'
                                    id='location'
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}>Course Location</InputDb>
                            </Tooltip>
                        </div>
                        <div className="form-group">
                            <DatePicker 
                                popoverSide='right'
                                value={days}
                                onChange={setDays}>Days of Week</DatePicker>
                        </div>
                        <div className="form-group">
                            <Dropdown
                                options={timezoneOptions} onSelect={handleSelectTimezone} placeholder='Timezone' />
                        </div>
                        <div className="form-group">
                            <TimePicker
                                value={startTime}
                                onChange={setStartTime}
                                popoverSide='right'>Start Time</TimePicker>
                        </div>
                        <div className="form-group">
                            <TimePicker
                                value={endTime}
                                onChange={setEndTime}
                                popoverSide='right'>End Time</TimePicker>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: ".5rem"}}>
                            <h3>Add Students</h3>
                            <Tooltip content={`Upload a .csv file with a column titled "email" or "emails" with your student's emails`}
                            direction='right'><span className='material-symbols-outlined' style={{fontSize: "20px"}}>info</span></Tooltip>
                        </div>
                        <div className="form-group">
                            <CSVUpload onUpload={handleCSVUpload}><span className='material-symbols-outlined' style={{fontSize: "20px"}}>upload_file</span>Upload Student List</CSVUpload>
                            {students.length > 0 && <p>Successfully uploaded <a onClick={() => setIsStudentsOpen(true)}style={{cursor: "pointer"}}>{students.length} students</a>.</p>}
                        </div>
                        <Modal 
                            isOpen={isStudentsOpen}
                            onClose={() => setIsStudentsOpen(false)} >
                            <ul>{students.map(student => 
                                <>
                                    <li style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>{student}
                                    <span 
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleRemoveStudent(student)}>&times;</span></li>
                                </>)}
                            </ul></Modal>
                        <Button 
                            design={'primary'} 
                            type="submit"
                            disabled={isLoading}
                            style={{width: "19rem"}} >Create Course</Button>
                    </form>
                </div>
                <div className="rightcol">
                    <div className="course-card">
                        <h3>{title || "Course Title"}</h3>
                        <h4 style={{color: "var(--primary-color)"}}>{abbreviation || 'ABC'} {number || 101}</h4>
                        <p>{(days && getAbbreviatedDays(days)) || "MoWeFr"} {startTime ? startTime.toString() : "9:00 AM"} - {endTime || "10:00 AM"}</p>
                        <p><span style={{color: "var(--primary-color)", fontWeight: "bold"}}>{enrollment || 50}</span> students</p>
                    </div>
                    {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
                </div>
            </div>
        </Dashboard>
    )
}

export default CreateCoursePage;