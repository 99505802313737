import React, { useState, useEffect } from 'react';
import { useSignup } from '../../hooks/Auth/useSignup';

// Components
import Input from '../../components/ui/Input/Input';
import Button from '../../components/ui/Button/Button';

import coursiqLogo from '../../img/coursiqLogo.svg';

const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const {signup, error, setError, isLoading} = useSignup();

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return
        }

        await signup(email, password)
    }

    return (
        <div className="page">
            <div className="card">
                <img src={coursiqLogo} alt="Coursiq Logo" width={140}/>
                <h2>Sign Up</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Input
                            type='email'
                            name='email'
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}>Email</Input>
                    </div>
                    <div className="form-group">
                        <Input
                            type={isPasswordVisible ? 'text' : 'password'}
                            name='password'
                            id='password'
                            autoComplete='new-password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onToggleVisibility={togglePasswordVisibility}
                            isPasswordVisible={isPasswordVisible}>Password</Input>
                    </div>
                    <div className="form-group">
                        <Input
                            type={isConfirmPasswordVisible ? 'text' : 'password'}
                            name='confirmPassword'
                            id='confirmPassword'
                            autoComplete='new-password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onToggleVisibility={toggleConfirmPasswordVisibility}
                            isPasswordVisible={isConfirmPasswordVisible}>Confirm Password</Input>
                    </div>
                    <Button design={'primary'} type='submit' disabled={isLoading} style={{width: "100%"}}>Sign Up</Button>
                </form>
                <p style={{marginBottom: "0"}}>Already have an account? <a href="/login">Log in</a></p>
            </div>
            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
            <p className="copyright">&copy;{new Date().getFullYear()} Connxting LLC. All rights reserved</p>
        </div>
    )
}

export default SignupPage;