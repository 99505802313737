import React, { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogin } from '../../hooks/Auth/useLogin';

// Components
import Input from '../../components/ui/Input/Input';
import Button from '../../components/ui/Button/Button';
import coursiqLogo from '../../img/coursiqLogo.svg';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { login, isLoading, error, setError } = useLogin();

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        await login(email, password);
    }

    return (
        <div className="page">
            <div className="card">
                <img src={coursiqLogo} alt="Coursiq Logo" width={140} />
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Input
                            type='email'
                            name='email'
                            id='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} >Email</Input>
                    </div>
                    <div className="form-group">
                        <Input
                            type={isPasswordVisible ? 'text' : 'password'}
                            name='password'
                            id='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onToggleVisibility={togglePasswordVisibility}
                            isPasswordVisible={isPasswordVisible}
                            autoComplete='current-password' >Password</Input>
                    </div>
                    <Button design={'primary'} type='submit' disabled={isLoading} style={{width: "100%"}}>Login</Button>
                </form>
                <p style={{marginBottom: "0"}}>Don't have an account? <a href="/signup">Sign up</a></p>
            </div>
            {error && <div className='message error'><strong>Error:</strong>&nbsp;{error} Please try again.</div>}
            <p className="copyright">&copy;{new Date().getFullYear()} Connxting LLC. All rights reserved</p>
        </div>
    )
}

export default LoginPage;