import React from 'react';
import styles from './InputDb.module.css';

function InputDb({ type = 'text', name, id, value, onChange, placeholder, children, onToggleVisibility, isPasswordVisible, ...rest }) {
    return (
        <div className="input-wrapper">
            <input 
                type={type} 
                name={name} 
                id={id} 
                value={value} 
                onChange={onChange}
                placeholder={placeholder} 
                {...rest}
                className="input-field"
            />
            <label htmlFor={id} className={styles.placeholderText} id={`placeholder-${id}`}>
                <div className={styles.labelText}>{children}</div>
            </label>
            {onToggleVisibility && (
                <span className="visibility-toggle" onClick={onToggleVisibility}>
                    <span className="material-symbols-outlined">
                        {isPasswordVisible ? 'visibility_off' : 'visibility'}
                    </span>
                </span>
            )}
        </div>
    );
}

export default InputDb;