import React, { useState } from "react";
import { useLogout } from "../../../../hooks/Auth/useLogout";
import { useNavigate } from "react-router-dom";
import './Navbar.css';

// Components
import ProfileDropdown from "../../../ui/ProfileDropdown/ProfileDropdown";
import Tooltip from "../../../ui/Tooltip/Tooltip";

import coursiqLogo from '../../../../img/coursiqLogo.svg';

const Navbar = ({active, photo, name, prefix, ...rest}) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const { logout } = useLogout();
    const navigate = useNavigate();

    const toggleMobileOpen = () => {
        setMobileOpen(!mobileOpen);
    }

    return (
        <nav className="navbar">
            <div className="leftSide">
                <img src={coursiqLogo} alt="Coursiq Logo" className="nav-logo"/>
                <ul className="nav-items">
                    <li className={active === "dashboard" ? "active" : ""} onClick={() => navigate('/dashboard')}>Dashboard</li>
                    <li className={active === "courses" ? "active" : ""} onClick={() => navigate('/dashboard/courses')}>Courses</li>
                    <Tooltip direction="below" content={"Coming soon..."}>
                        <li className={active === "attendance" ? "active" : ""}>Attendance</li>
                    </Tooltip>
                </ul>
            </div>
            <div className="rightSide">
                <ProfileDropdown
                    photo={photo}
                    name={name}
                    prefix={prefix} />
            </div>
            <div className="mobile-navbar">
                {!mobileOpen ? (
                    <span className="material-symbols-outlined clickable" onClick={toggleMobileOpen}>menu</span>
                ) : (
                    <span className="material-symbols-outlined clickable" onClick={toggleMobileOpen}>menu_open</span>
                )}
            </div>
            {mobileOpen && (
                <div className="mobile-dropdown-menu">
                    <ul className="mobile-nav-items">
                        <li className={active === "dashboard" ? "active" : ""} onClick={() => navigate('/dashboard')}><span className="material-symbols-outlined">team_dashboard</span>Dashboard</li>
                        <li className={active === "courses" ? "active" : ""} onClick={() => navigate('/dashboard/courses')}><span className="material-symbols-outlined">school</span>Courses</li>
                        <Tooltip direction="left" content={"Coming soon..."}>
                            <li className={active === "attendance" ? "active" : ""} style={{padding: ".5rem .25rem", display: "flex", alignItems: "center", gap: ".5rem", borderRadius: ".4rem"}}><span className="material-symbols-outlined">groups</span>Attendance</li>
                        </Tooltip>
                        <Tooltip direction="left" content={"Coming soon..."}>
                            <li style={{padding: ".5rem .25rem", display: "flex", alignItems: "center", gap: ".5rem", borderRadius: ".4rem"}}><span className="material-symbols-outlined">manage_accounts</span>Account Settings</li>
                        </Tooltip>
                        <li onClick={logout}><span className="material-symbols-outlined">logout</span>Sign Out</li>
                    </ul>
                </div>
            )}
        </nav>
    )
}

export default Navbar;