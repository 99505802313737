import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../useAuthContext';

export const useOnboarding = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userAttendance, dispatch } = useAuthContext();
    const navigate = useNavigate();

    const onboarding = async (name, honorific, phone, school, schoolLevel, jobTitle) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/auth/signup/onboarding', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({token: userAttendance.token, name, honorific, phone, school, schoolLevel, jobTitle})
        });
        
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            localStorage.setItem('userAttendance', JSON.stringify(json.user));

            dispatch({ type: 'LOGIN', payload: json.user })

            setIsLoading(false);

            navigate('/dashboard');
        }
    }
    return { onboarding, isLoading, error, setError }
}