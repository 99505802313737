import { createContext, useReducer, useEffect } from 'react';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { userAttendance: action.payload };
        case 'LOGOUT':
            return { userAttendance: null };
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        userAttendance: JSON.parse(localStorage.getItem('userAttendance')) || null
    });

    // Update Context Provider from Local Storage
    useEffect(() => {
        localStorage.setItem('userAttendance', JSON.stringify(state.userAttendance))
    }, [state.userAttendance]);

    return (
        <AuthContext.Provider value={{...state, dispatch}}>
            { children }
        </AuthContext.Provider>
    )
}