import './Button.modules.css';

function Button ({ design, children, ...rest }) {
    const buttonClass = `button ${design ? `button-${design}` : 'button-primary'}`

    return (
        <button className={buttonClass} {...rest}>{children}</button>
    )
}

export default Button;