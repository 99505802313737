import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import { useAuthContext } from '../useAuthContext';
import { useCourseContext } from '../useCourseContext';

export const useCreateAttendance = () => {
    const [error, setError] = useState(null);
    const [isLoadingAttendance, setIsLoadingAttendance] = useState(false);
    const [code, setCode] = useState('');
    const [link, setLink] = useState('');
    const [message, setMessage] = useState('');
    const { userAttendance, dispatch } = useAuthContext();
    const { userCourses, dispatchCourse } = useCourseContext();
    const navigate = useNavigate();

    const createAttendance = async (courseId) => {
        setIsLoadingAttendance(true);
        setError(null);

        const response = await fetch('/api/course/attendance/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ userToken: userAttendance.token, courseId })
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoadingAttendance(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoadingAttendance(false);
            setCode(json.qrCode);
            setLink(json.link);
        }
    }
    return { createAttendance, code, link, isLoadingAttendance, error, setError };
}