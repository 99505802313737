import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.modules.css';

const Dropdown = ({ options, placeholder = 'Select an option', onSelect, initializedValue = null, searchable = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const optionsRef = useRef([]);

  useEffect(() => {
    if (isOpen && searchable && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen, searchable]);

  useEffect(() => {
    if (initializedValue) {
      const matchedOption = options.find(option => option.value === initializedValue);
      if (matchedOption) {
        setSelectedOption(matchedOption);
      }
    }
  }, [initializedValue, options]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchValue(''); // Clear search input
    onSelect(option.value);
  };

  const handleHeaderClick = (e) => {
    // Prevent default focus behavior to avoid double triggering
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    // Delay to allow click events to register
    setTimeout(() => {
      if (!dropdownRef.current.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 100);
  };

  const handleAddNew = () => {
    if (searchValue.trim() !== '') {
      const newOption = { text: searchValue, value: searchValue };
      handleSelect(newOption);
    }
  };

  const getFilteredAndSortedOptions = () => {
    if (!searchable) return options;

    const searchLower = searchValue.toLowerCase();
    return options
      .filter(option => option.text.toLowerCase().includes(searchLower))
      .sort((a, b) => {
        const aContainsInOrder = a.text.toLowerCase().includes(searchLower);
        const bContainsInOrder = b.text.toLowerCase().includes(searchLower);

        if (aContainsInOrder && !bContainsInOrder) {
          return -1;
        }
        if (!aContainsInOrder && bContainsInOrder) {
          return 1;
        }
        return a.text.localeCompare(b.text);
      });
  };

  const filteredAndSortedOptions = getFilteredAndSortedOptions();

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex + 1) % filteredAndSortedOptions.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex((prevIndex) => (prevIndex - 1 + filteredAndSortedOptions.length) % filteredAndSortedOptions.length);
        break;
      case 'Enter':
        e.preventDefault();
        if (filteredAndSortedOptions[highlightedIndex]) {
          handleSelect(filteredAndSortedOptions[highlightedIndex]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpen && optionsRef.current[highlightedIndex]) {
      optionsRef.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [highlightedIndex, isOpen]);

  return (
    <div 
      className={`dropdown ${isOpen ? 'active' : ''} ${selectedOption ? 'filled' : ''}`} 
      ref={dropdownRef}
      tabIndex={0}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
    >
      <div className="dropdown-header" onMouseDown={handleHeaderClick}>
        <span className="placeholder-text">
          <span className="label-text">{placeholder}</span>
        </span>
        <span className="selected-text">{selectedOption ? selectedOption.text : ''}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''} material-symbols-outlined`}>keyboard_arrow_down</span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-options">
            {searchable && (
              <div className="dropdown-search">
                <input
                  type="text"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search or add new..."
                  className="search-input"
                  ref={searchInputRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !filteredAndSortedOptions.find(option => option.text.toLowerCase() === searchValue.toLowerCase())) {
                      handleAddNew();
                    }
                  }}
                />
              </div>
            )}
            {filteredAndSortedOptions.map((option, index) => (
              <div
                key={index}
                className={`dropdown-option ${index === highlightedIndex ? 'highlighted' : ''}`}
                onMouseEnter={() => setHighlightedIndex(index)}
                onClick={() => handleSelect(option)}
                ref={(el) => (optionsRef.current[index] = el)}
              >
                {option.text}
              </div>
            ))}
            {searchable && searchValue.trim() && !filteredAndSortedOptions.find(option => option.text.toLowerCase() === searchValue.toLowerCase()) && (
              <div
                className="dropdown-option add-new-option"
                onClick={handleAddNew}
              >
                Add "{searchValue}"
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
