import React, { useState } from 'react';
import styles from './Tooltip.module.css';

const Tooltip = ({ children, content, direction = 'above', ...rest }) => {
    const [isVisible, setIsVisible] = useState(false);

    const showTooltip = () => setIsVisible(true);
    const hideTooltip = () => setIsVisible(false);

    return (
        <div 
            className={styles.tooltipWrapper}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            {...rest}
        >
            {children}
            {isVisible && (
                <div 
                    className={`${styles.tooltipContent} ${styles[direction]}`}
                    onMouseEnter={showTooltip}   // Keep the tooltip visible when hovering over it
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
