import React, { useState } from 'react';
import { useNavigate } from'react-router-dom';
import { useAuthContext } from '../useAuthContext';
import { useCourseContext } from '../useCourseContext';

export const useCreateCourse = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userAttendance, dispatch } = useAuthContext();
    const { userCourses, dispatchCourse } = useCourseContext();
    const navigate = useNavigate();

    const createCourse = async (courseTitle, courseAbbreviation, courseNumber, courseLocation, daysOfWeek, timezone, startTime, endTime, students) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('/api/course/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({userToken: userAttendance.token, courseTitle, courseAbbreviation, courseNumber, courseLocation, daysOfWeek, timezone, startTime, endTime, students})
        });

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            const newCourse = json.course;

            dispatchCourse({ type: 'ADD', payload: newCourse });

            setIsLoading(false);

            navigate('/dashboard/courses')
        }
    }
    return { createCourse, isLoading, error, setError };
}